// HistorialTareas.js
import React from "react";

export default function HistorialTareas() {
return (
    <div>
    <h1>Historial de Tareas</h1>
    <p>Esta es la página de historial de tareas.</p>
    </div>
);
}
